import restaurant from "./images/adam.jpg";
import img_card from "./images/n.jpeg";
import imgOmSuflet from "./images/h8.jpg";
import imgheader from "./images/h2.jpg";
import imgheadermiini from "./images/h11.jpg";
import imgheadermiinimb from "./images/h11.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        mire: "Valentin",
        mireasa: "Elena",
        data: "23 Iulie 2022",
        data_confirmare: "10 iulie 2022",
        savedata: "~ Ne Căsătorim !!! ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "elenamocanu682@gmail.com", 
       tel: "+39 389 783 9535",
       phone: "tel:+393897839535",
       viber: "viber://chat?number=%2B393897839535",
       whatsapp: "https://wa.me/+393897839535",
       messenger: "https://www.messenger.com/t/elena.mocan2",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Adam și Eva",
            data: "23 iulie 2022, sâmbătă, ora 18:00",
            adress: "str. Păcii 2/2, Durlesti, Moldova",
            harta: "https://goo.gl/maps/SRwCAn1KbpWc8Pua8",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10877.509308379937!2d28.7657109!3d47.0328262!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd20a8b9f74d7106d!2sAdam%20%26%20Eva!5e0!3m2!1sro!2s!4v1651243962628!5m2!1sro!2s",
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Vasile & Diana",
            parintii_nume2: "Ghenadie & Oxana",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Vasile & Svetlana",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "\"Căsnicia este o artă ce trebuie să o creezi în fiecare zi\"",
            message: " – R. Tagore",
        }
    ],

}

export default data;