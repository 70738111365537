import React from "react"
import "../css/ImgInvitatie.css"
import noi from "../images/n.jpeg"



const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <div className="text mb-5">
                            <h5>Noi,</h5>

                            <img id="img_noi" src={noi} />
                            <h4 className="pt-2">Valentin & Elena</h4>
                            <h5>
                                Am ales data 
                            </h5>
                            <h4>
                                23 iulie 2022
                            </h4>

                            <h5>
                                ca ziua în care să ne căsătorim. <br />
                              <br/>  Alături de noi vor fi părinții, nașii, <br/> dar și cei mai aleși și dragi oameni sufletului nostru!
                            </h5>
                           {/* <h4>Istratii </h4>
                            <h4>Vladimir & Ana</h4>

                            <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                                <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                </svg>
                                <br />
                            </h5>
                            <h4>Parascovia Mocanu </h4>

                            <h5>Și cu cei care ne vor sta alături de acum încolo </h5>
                            <img id="img_nasi" src={nasi} />
                            <h4>Ababii</h4>
                            <h4>Nicolae & Rodica</h4>*/}
<br/>
                            <h5>
                                De aceea vă invităm să luați parte la sărbătoarea <br/> fericirii noastre începând cu ora 18:00.
                            </h5>
                            <h4>
                                La restaurantul ”Adam și Eva”
                            </h4>
                            <h5>
                                str. Păcii 2/2, Durlești, Moldova
                            </h5>


                        </div>










                        <h4 class="card-footer">Vă așteptăm cu drag!</h4>

                        <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>

                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;